"use client";

import { useSignIn } from "@clerk/nextjs";
import { OAuthStrategy } from "@clerk/nextjs/dist/types/server";
import { Box, Button, Container, Typography, styled } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { FormEvent } from "react";
import LoadingPage from "../../components/LoadingPage";
import { orange, tan } from "../../components/Theme";
import { StyledTextField } from "../../styles/styledComponents";
import { useToast } from "../../components/Toast/ToastContext";

const Login = ({ referer }: { referer: string | null }) => {
  const { isLoaded, signIn, setActive } = useSignIn();
  const router = useRouter();

  const { enqueueToast } = useToast();

  if (!isLoaded) return <LoadingPage />;

  const signInWith = (strategy: OAuthStrategy) => {
    return signIn.authenticateWithRedirect({
      strategy,
      redirectUrl: "/sso-callback",
      redirectUrlComplete: "/",
    });
  };

  // start the sign In process.
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!event.target) {
      return;
    }
    const { emailAddress, password } = event.target as HTMLFormElement;
    try {
      const result = await signIn.create({
        identifier: emailAddress.value,
        password: password.value,
      });

      if (result.status === "complete") {
        await setActive({ session: result.createdSessionId });

        const redirectUrl = new URL(referer ?? "/");
        if (redirectUrl.hostname.includes("happydogs") || ["", "/login", "/register"].includes(redirectUrl.pathname)) {
          redirectUrl.pathname = "/";
        }

        router.push(redirectUrl.pathname);
      } else {
        /*Investigate why the login hasn't completed */
        console.log(result);
      }
    } catch (err: unknown) {
      const errorMessage = err instanceof Error ? err.message : "En feil har oppstått";
      enqueueToast(errorMessage, "error");
    }
  };

  // const PROTOTYPE_URL = "https://www.figma.com/proto/H27n1NgHHeLdPadOqO2CaE/HD---Web-site?page-id=15%3A3&type=design&node-id=27-927&viewport=4570%2C2728%2C1.11&scaling=min-zoom&starting-point-node-id=27%3A938";

  return (
    <Container
      maxWidth="lg"
      sx={{ display: "flex", marginTop: { md: "50px", xs: "30px" }, justifyContent: "center", paddingBottom: "50px" }}
    >
      <Box
        component="form"
        display={"flex"}
        flexDirection={"column"}
        alignSelf={"center"}
        gap={{ md: "30px", xs: "20px" }}
        paddingTop={{ md: "50px", xs: "40px" }}
        paddingBottom={{ md: "50px", xs: "40px" }}
        paddingLeft={"30px"}
        paddingRight={"30px"}
        sx={{
          width: { xs: "100%", sm: "400px" },
          backgroundColor: "#fff",
          border: `1px solid ${tan[50]}`,
          borderRadius: "20px",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        }}
        onSubmit={handleSubmit}
      >
        <Typography variant="h3">Logg inn</Typography>
        <Box display={"flex"} flexDirection={"column"} gap="20px" width={"100%"}>
          <StyledTextField label="Email" id="emailAddress" type="email" required />

          <StyledTextField label="Passord" required id="password" type="password" />

          <Button type="submit" variant="contained" color="primary" sx={{ width: "100%" }}>
            Logg inn
          </Button>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap="20px" width={"100%"} textAlign={"center"}>
          <Link href="./forgot-password" color={orange[100]}>
            <Typography variant="body2" sx={{ textDecoration: "underline" }}>
              {" "}
              Har du glemt ditt passord?
            </Typography>
          </Link>
          <Typography variant="body2">
            Har du ikke bruker enda?{" "}
            <Link href="./register" color={orange[100]} style={{ textDecoration: "underline" }}>
              Registrer deg
            </Link>
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap="20px" width={"100%"}>
          <Button variant="outlined" color="secondary" onClick={() => signInWith("oauth_google")} sx={{ width: "100%" }}>
            Bruk Google
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => signInWith("oauth_facebook")} sx={{ width: "100%" }}>
            Bruk Facebook
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;

const RegisterImage = styled("img")`
  display: block;
  height: 550px;
  @media screen and (max-width: 1200px) {
    height: 380px;
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
