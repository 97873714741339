import { CircularProgress, Container } from "@mui/material";

const LoadingPage = () => {
  return (
    <Container maxWidth="lg">
      <CircularProgress color="primary"></CircularProgress>
    </Container>
  );
};

export default LoadingPage;
